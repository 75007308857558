.mascot-logo {
  margin-bottom: 10%;
  width: 20%;
  text-align: center;
}

.container {
  display: flex;
  height: 100vh;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  #background-color: blue;
}

.item {
  width: 50%;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-button {
  display: flex;
  margin: 0 auto;
}
